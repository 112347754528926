import { User } from 'firebase/auth';
import { useCallback, useState } from 'react';
import { BookingsPerDate } from '../config/types';
import useApiClient from './useApiClient';

const useBooking = (user: User | null | undefined) => {
   const apiClient = useApiClient(user);

   const [loadingCreateBooking, setLoadingCreateBooking] = useState(false);
   const [loadingGetBookings, setLoadingGetBookings] = useState(false);
   const [loadingDeleteBooking, setLoadingDeleteBooking] = useState(false);
   const [loadingSendNotification, setLoadingSendNotification] =
      useState(false);
   const [successfulBooking, setSuccessfulBooking] = useState(false);
   const [successfulNotification, setSuccessfulNotification] = useState(false);
   const [hasErrorCreateBooking, setHasErrorCreateBooking] = useState(false);
   const [hasErrorSendNotification, setHasErrorSendNotification] =
      useState(false);
   const [createBookingErrorMessage, setCreateBookingErrorMessage] =
      useState('');
   const [hasErrorDeleteBooking, setHasErrorDeleteBooking] = useState(false);
   const [bookings, setBookings] = useState<Array<BookingsPerDate | any>>([]);
   const [pastBookings, setPastBookings] = useState<
      Array<BookingsPerDate | any>
   >([]);
   const [loadingGetPastBookings, setLoadingGetPastBookings] = useState(false);

   const createBooking = useCallback(
      async (
         displayName: string | any,
         email: string,
         bookingDate: Date,
         selectedFoodMenu: string,
         userHasKeys: boolean,
         keyArrivalTime: string,
      ) => {
         setLoadingCreateBooking(true);
         bookingDate.setHours(0, 0, 0);
         try {
            await apiClient.post('/bookings', {
               displayName,
               email,
               bookingDate,
               selectedFoodMenu,
               userHasKeys,
               keyArrivalTime,
            });
            setHasErrorCreateBooking(false);
            setSuccessfulBooking(true);
         } catch (error: any) {
            setHasErrorCreateBooking(true);
            setCreateBookingErrorMessage(error.response.data.errorMessage);
            setSuccessfulBooking(false);
         }
         setLoadingCreateBooking(false);
         setTimeout(() => {
            setSuccessfulBooking(false);
         }, 4000);
         setTimeout(() => {
            setHasErrorCreateBooking(false);
         }, 4000);
      },
      [apiClient],
   );

   const getBookings = useCallback(async () => {
      setLoadingGetBookings(true);
      const response = await apiClient.get('/bookings');

      setBookings(response.data);
      setLoadingGetBookings(false);
   }, [apiClient]);

   const getPastBookings = useCallback(
      async (displayName: string | undefined | null) => {
         setLoadingGetPastBookings(true);
         const response = await apiClient.get(
            `/bookings/past?userName=${displayName}`,
         );
         setPastBookings(response.data);
         setLoadingGetPastBookings(false);
      },
      [apiClient],
   );

   const deleteBooking = useCallback(
      async (id: string) => {
         setLoadingDeleteBooking(true);
         try {
            await apiClient.delete(`/bookings/${id}`);
            setHasErrorDeleteBooking(false);
         } catch (error) {
            setHasErrorCreateBooking(true);
         }
         setLoadingDeleteBooking(false);
      },
      [apiClient],
   );

   const sendNotification = useCallback(
      async (
         displayName: string | any,
         bookingDate: string,
         message: string,
      ) => {
         try {
            setLoadingSendNotification(true);
            setHasErrorSendNotification(false);
            await apiClient.post('/notifications', {
               userDisplayName: displayName,
               bookingDate,
               notificationMessage: message,
            });
            setSuccessfulNotification(true);
         } catch (error) {
            setHasErrorSendNotification(true);
            setSuccessfulNotification(false);
         } finally {
            setLoadingSendNotification(false);
         }
      },
      [apiClient],
   );

   return {
      createBooking,
      getBookings,
      deleteBooking,
      getPastBookings,
      sendNotification,
      bookings,
      loadingCreateBooking,
      loadingDeleteBooking,
      loadingGetBookings,
      successfulBooking,
      successfulNotification,
      hasErrorCreateBooking,
      hasErrorSendNotification,
      createBookingErrorMessage,
      hasErrorDeleteBooking,
      pastBookings,
      loadingGetPastBookings,
      loadingSendNotification,
      setSuccessfulNotification,
      setHasErrorSendNotification,
   };
};

export default useBooking;
