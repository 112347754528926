import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginCard from '../../components/login/LoginCard';
import { allowedEmailDomain } from '../../config/constants';
import { useAuthUser } from '../../hooks/useAuthUser';

const LoginScreen = () => {
   const { login, user, logout } = useAuthUser({ isLocal: false });
   const navigate = useNavigate();

   function goToHome() {
      navigate('/');
   }

   const doLogin = () => {
      login().then((res) => {
         if (!res.user.email?.includes(allowedEmailDomain))
            return logout().then(() => {
               window.location.href =
                  'mailto:info@grupoesfera.com.ar?subject=Quiero%20unirme%20a%20GE!&body=Tengo%20tantas%20ganas%20de%20formar%20parte%20de%20Grupo%20Esfera%20que%20estoy%20intentando%20sacar%20turno%20para%20ir%20a%20su%20oficina%20sin%20siquiera%20estar%20empleado.%20Les%20dejo%20mi%20CV%20adjunto.';
            });

         goToHome();
      });
   };

   useEffect(() => {
      if (user) {
         goToHome();
      }
   });

   return <LoginCard login={doLogin} />;
};

export default LoginScreen;
