import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCkddyz2CSqhVCQgxp48gOk5WHfnTe1N7E",
  authDomain: "backoffice-ge.firebaseapp.com",
  projectId: "backoffice-ge",
  storageBucket: "backoffice-ge.appspot.com",
  messagingSenderId: "739819894292",
  appId: "1:739819894292:web:8ff5c8539746c2fc0e9901"
};

const app = initializeApp(firebaseConfig);

export const authFirebase = getAuth(app);
