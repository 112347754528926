import { User } from 'firebase/auth';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../img/logo-esfera-blanco.png';
import Button from '../button/Button';
import MenuKebab from '../menuKebab/MenuKebab';
import './header.css';

type HeaderType = {
   user?: User | null | undefined;
   isLoggedUser: boolean;
   logout: () => void;
};

const Header = ({ user, isLoggedUser, logout }: HeaderType) => {
   const { pathname } = useLocation();

   const firstName = isLoggedUser
      ? user?.displayName?.split(' ')[0] + '!'
      : 'perri';

   const photoURL = user?.photoURL ? user.photoURL : undefined;

   const goImpacto = (event: any) => {
      goTo(event,'https://impacto.grupoesfera.com.ar/');
   };

   const goHabilidades = (event: any) => {
      goTo(event,'https://habilidades.grupoesfera.com.ar/');
   };

   const goTo = (e: any,url: string ) => {
      e.preventDefault();
      window.location.href = url;
   }

   return (
         <nav>
         <Link to="/">
         <img className="logo" src={Logo} alt="logo grupo esfera" />
         </Link>
         <div className="header-user-content">
         {user?.displayName && (
               <div className="header-button-container">
               {pathname !== '/ranking' && (
                     <Link data-cy={`boton-ranking`} to="/ranking" className="header-button-badge">
                     <span>NEW</span>
                     <Button onClick={() => {}}>
                     🏆
                     <div className="hidden-sm header-button-text">
                     {' '}
                     RANKING
                     </div>
                     </Button>
                     </Link>
                     )}
               <Button onClick={goImpacto}>
               💵
               <div data-cy={`boton-impacto`} className="hidden-sm header-button-text">
               {' '}
               IMPACTO
               </div>
               </Button>
               <Button onClick={goHabilidades}>
               🧘‍♂️
               <div data-cy={`boton-habilidades`} className="hidden-sm header-button-text">
               {' '}
               HABILIDADES
                  </div>
                  </Button>
                  </div>
                  )}
   <div id="user-name-container">
      <span className="greeting-text">Holi, {firstName}</span>
      {photoURL && (
            <img id="profile-avatar" src={photoURL} alt="profile" />
            )}
   </div>
   {user?.displayName && <MenuKebab onCloseSession={logout} />}
   </div>
      </nav>
      );
};

export default Header;
