import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { PeopleAttendance } from '../../../config/types';
import Button from '../../button/Button';
import './people-card.css';

type PeopleCardProps = {
   ranking: PeopleAttendance[];
   full: boolean;
};

const PeopleCard = ({ ranking, full }: PeopleCardProps) => {
   const [fullRankingAlertStep, setFullRankingAlertStep] = useState<
      'boton' | 'declaracion' | 'incorrecta' | null
   >('boton');
   const [respuestaDeclaracion, setRespuestaDeclaracion] = useState<
      'SI' | 'NO' | null
   >(null);

   return (
      <div className={'people-card'}>
         <h2 className={'card-title'}>Personas</h2>
         <div className={'card-content'}>
            {ranking.map((people, index) => (
               <div key={index} className={'card-content-item'}>
                  <span className="card-content-item-medal">
                     {index === 0 && '🥇'}
                     {index === 1 && '🥈'}
                     {index === 2 && '🥉'}
                  </span>
                  <span>{people.userDisplayName}</span>
                  <span>{people.count}</span>
               </div>
            ))}
         </div>
         {!full && fullRankingAlertStep === 'boton' && (
            <div className="button-container">
               <Button
                  onClick={() => {
                     setFullRankingAlertStep('declaracion');
                  }}
               >
                  Ver ranking completo
               </Button>
            </div>
         )}
         {!full && fullRankingAlertStep === 'declaracion' && (
            <div className="full-ranking-alert-container">
               <p className={'full-ranking-alert-message-title'}>
                  Declaración de intencion no policíaca
               </p>
               <p className={'full-ranking-alert-message'}>
                  Para poder visualizar el ranking completo necesitas declarar
                  que tus intenciones son meramente relacionadas a la curiosidad
                  y de ningún modo la información será utilizada gorrudamente.
               </p>
               <p className={'full-ranking-alert-message'}>
                  Voy a ver el ranking completo con fines policíacos.
               </p>
               <div className="full-ranking-alert-container-actions">
                  <Button
                     onClick={() => {
                        setRespuestaDeclaracion('SI');
                        setFullRankingAlertStep('incorrecta');
                     }}
                  >
                     SI 👮
                  </Button>
                  <Button
                     onClick={() => {
                        setRespuestaDeclaracion('NO');
                        setFullRankingAlertStep('incorrecta');
                     }}
                  >
                     NO 🏳️
                  </Button>
               </div>
            </div>
         )}
         {!full && fullRankingAlertStep === 'incorrecta' && (
            <div className="full-ranking-alert-container">
               <p className={'full-ranking-alert-message-title'}>
                  Respondiste que {respuestaDeclaracion}
               </p>
               <p className={'full-ranking-alert-message'}>
                  {respuestaDeclaracion === 'SI'
                     ? 'No te podemos permitir ver el ranking completo.👮'
                     : 'Eso significa que tus fines son policíacos pero no querés admitirlo. No te podemos permitir ver el ranking completo.👮'}
               </p>
               {respuestaDeclaracion === 'NO' && (
                  <p className={'full-ranking-alert-message-info'}>
                     (Agrega el query param `full` a la URL)
                  </p>
               )}
            </div>
         )}
      </div>
   );
};

export default PeopleCard;
