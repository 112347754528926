import axios from 'axios';

import urlBase from './useBasepath';
import { User } from 'firebase/auth';

const TOKEN_PREFIX = 'Bearer';

const useApiClient = (user: User | null | undefined) => {
   const instance = axios.create({ baseURL: urlBase() });
   instance.interceptors.request.use(async (request) => {
      if (user) {
         try {
            const token = await user.getIdToken(false);
            // @ts-ignore
            request.headers.Authorization = `${TOKEN_PREFIX} ${token}`;
         } catch (error) {
            console.log('Error al obtener el token de autorización, ', error);
         }
      }
      return request;
   });

   return instance;
};

export default useApiClient;
