import React, { useEffect, useState } from 'react';
import './OfficeCard.css';
import Button from '../../button/Button';

const OfficeCard = () => {
   const [officeAddress, setOfficeAddress] = useState<string>('');

   const copy = async () => {
      if ('clipboard' in navigator) {
         return await navigator.clipboard.writeText(officeAddress);
      } else {
         return document.execCommand('copy', true, officeAddress);
      }
   };

   useEffect(() => {
      setOfficeAddress('Av. Rivadavia 2134, CABA');
   }, []);

   return (
      <div className={'card office-card'}>
         <h2 className={'card-title'}>Datos de la Ofi</h2>
         <div className={'card-content office-card-content'}>
            <label className={'input-label'}>Direccion: {officeAddress}</label>
            <label className={'input-label'}>Entrecalles: Rincon y Pasco</label>
            <label className={'input-label'}>Nota</label>
            <p className="office-text-details">
               El piso es el Nro 3, edificio cierra despues de las 08:00 PM, si
               se quedan hasta despues de esa hora necesitan tener llaves.
            </p>
         </div>
         <div className="button-container">
            <Button onClick={copy}>COPIAR DIRECCION</Button>
         </div>
      </div>
   );
};

export default OfficeCard;
