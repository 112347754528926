import React, { useState } from 'react';
import LogOutIcon from '../../img/icono-menu.svg';
import Button from '../button/Button';
import './MenuKebab.css';

interface MenuKebabProps {
   onCloseSession: () => void;
}

const imgsrc = LogOutIcon;

const MenuKebab = ({ onCloseSession }: MenuKebabProps) => {
   const [showOptions, setShowOptions] = useState<boolean>(false);

   const displayOptions = () => {
      return setShowOptions(!showOptions);
   };

   const closeSession = () => {
      setShowOptions(false);
      onCloseSession();
   };

   return (
      <div
         className="kebab-menu-container"
         onClick={() => {
            displayOptions();
         }}
      >
         <img
            className="kebab-icon"
            src={imgsrc}
            alt={'boton-menu-kebab'}
            data-cy={`boton-menu-kebab`}
         />
         {showOptions && (
            <div className="kebab-button-container">
               <Button onClick={closeSession}>🏃Salir</Button>
            </div>
         )}
      </div>
   );
};

export default MenuKebab;
