import React from 'react';
import './button.css';

type ButtonProps = {
   onClick: (e: any) => void;
   isDisabled?: boolean;
   children: React.ReactNode;
};

const Button = ({ onClick, isDisabled, children }: ButtonProps) => {
   return (
      <button
         className={isDisabled ? 'button disabled' : 'button'}
         onClick={!isDisabled ? onClick : (e: any) => {}}
      >
         <div className="button-content-container">{children}</div>
      </button>
   );
};

export default Button;
