import configData from '../config/config.json';

const isInEsfera = () => {
   return window.location.hostname === configData.ESFERA_SERVER;
};

const urlBase = (): string => {
   if (isInEsfera()) {
      return configData.BASE_URL_ESFERA_SERVER;
   } else {
      return configData.BASE_URL_LOCAL;
   }
};

export default urlBase;
