import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { CombosData } from '../../../config/types';
import './combos-card.css';

type CombosCardProps = {
   ranking: CombosData[];
};

const CombosCard = ({ ranking }: CombosCardProps) => {
   return (
      <div className={'combos-card'}>
         <h2 className={'card-title'}>Inseparables</h2>
         <div className={'card-content'}>
            {ranking.map((combo, index) => (
               <div key={index} className={'card-content-item'}>
                  <span className="card-content-item-medal">
                     {index === 0 && '🥇'}
                     {index === 1 && '🥈'}
                     {index === 2 && '🥉'}
                  </span>
                  <span>
                     {combo.userDisplayName} - {combo.icon}
                  </span>
                  <span>
                     {combo.percentage}% ({combo.count}/{combo.totalBookings})
                  </span>
               </div>
            ))}
         </div>
      </div>
   );
};

export default CombosCard;
