import React from 'react';
import { BookingsPerDate } from '../../../config/types';
import './booking-table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { TailSpin } from 'react-loader-spinner';

type BookingTableProps = {
   bookings: BookingsPerDate[];
   userDisplayName?: string | null | undefined;
   deleteBooking: (id: string) => void;
   loadingDeleteBooking: boolean;
};

const BookingTable = ({
   bookings,
   userDisplayName,
   deleteBooking,
   loadingDeleteBooking,
}: BookingTableProps) => {
   const sliceFoodCombinatedIcons = (icons: string) => {
      const foodIconsArray = [];
      foodIconsArray.push(icons.slice(0, 2));
      foodIconsArray.push(icons.slice(2, 4));
      return foodIconsArray;
   };

   return (
      <>
         {bookings.length ? (
            <h1>Detalle de reservas</h1>
         ) : (
            <div className="no-booking-text-container">
               <h1>No hay reservas para los próximos días</h1>
            </div>
         )}
         <div className="assistants-card-container">
            {bookings.map((i) => (
               <div className="assistants-card" key={i.date}>
                  <header className="assistants-card-header">
                     <h3>{i.weekDay + ' ' + i.date}</h3>
                     <p className="availability-text">
                        Cupos disponibles: {i.availability}
                     </p>
                  </header>

                  <ul className="assistants-list">
                     {i.assistants.map((a) => (
                        <div key={a.id} className="assistant">
                           <div className="text-container">
                              <li className="user-name" key={a.id}>
                                 {a.userDisplayName}
                              </li>
                              {a.keyArrivalTime && (
                                 <span className="key-arrival-time">
                                    {a.keyArrivalTime}
                                 </span>
                              )}
                           </div>
                           <div className="icon-container">
                              {a.keyArrivalTime && (
                                 <div className="round-icon-container">
                                    {'🔑'}
                                 </div>
                              )}
                              {a.icon.length === 2 ? (
                                 <div className="round-icon-container">
                                    {a.icon}
                                 </div>
                              ) : (
                                 sliceFoodCombinatedIcons(a.icon).map((i) => (
                                    <div
                                       key={i}
                                       className="round-icon-container"
                                    >
                                       {i}
                                    </div>
                                 ))
                              )}
                              {userDisplayName === a.userDisplayName && (
                                 <button
                                    className="icon-button"
                                    onClick={() => deleteBooking(a.id)}
                                 >
                                    {loadingDeleteBooking ? (
                                       <TailSpin
                                          color="#fa50ae"
                                          visible={true}
                                          height={16}
                                          width={16}
                                       />
                                    ) : (
                                       <FontAwesomeIcon
                                          icon={faTrash}
                                          className="icon"
                                       />
                                    )}
                                 </button>
                              )}
                              {userDisplayName !== a.userDisplayName && (
                                 <div className="horizontal-spacer"></div>
                              )}
                           </div>
                        </div>
                     ))}
                  </ul>
               </div>
            ))}
         </div>
      </>
   );
};

export default BookingTable;
