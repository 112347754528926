import React, { useState, useCallback } from 'react';
import './booking-card.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../button/Button';
import { FOOD_OPTIONS } from '../../../config/constants';
import { TailSpin } from 'react-loader-spinner';
import es from 'date-fns/locale/es';
import { User } from 'firebase/auth';

type BookingCardProps = {
   getBookings: () => void;
   createBooking: (
      displayName: string | any,
      email: string | any,
      bookingDate: Date,
      selectedFoodMenu: string,
      userHasKeys: boolean,
      keyArrivalTime: string,
   ) => void;
   loadingCreateBooking: boolean;
   successfulBooking: boolean;
   hasErrorCreateBooking: boolean;
   createBookingErrorMessage: string;
   user?: User | null | undefined;
   selectedDate: Date;
};

const BookingCard = ({
   getBookings,
   createBooking,
   loadingCreateBooking,
   successfulBooking,
   hasErrorCreateBooking,
   createBookingErrorMessage,
   user,
   selectedDate,
}: BookingCardProps) => {
   const [bookedDate, setBookedDate] = useState(selectedDate);
   const [selectedFoodMenu, setSelectedFoodMenu] = useState('0');
   const isBookingDisabled = selectedFoodMenu === '0' || loadingCreateBooking;
   const [userHasKeys, setUserHasKeys] = useState(false);
   const [keyArrivalTime, setKeyArrivalTime] = useState('');

   const handleSelectedFoodMenu = (
      event: React.ChangeEvent<HTMLSelectElement>,
   ) => {
      setSelectedFoodMenu(event.target.value);
   };

   const handleCheckKeys = useCallback(() => {
      setUserHasKeys((prevValue) => !prevValue);
   }, []);

   const handleKeyArrivalTimeChange = useCallback((event) => {
      setKeyArrivalTime(event.target.value);
   }, []);

   const handleBookingPress = useCallback(async () => {
      // eslint-disable-next-line
      await createBooking(
         user?.displayName,
         user?.email,
         bookedDate,
         selectedFoodMenu,
         userHasKeys,
         keyArrivalTime,
      );
      getBookings();
   }, [
      user,
      bookedDate,
      selectedFoodMenu,
      userHasKeys,
      keyArrivalTime,
      createBooking,
      getBookings,
   ]);

   registerLocale('es', es);

   return (
      <div className={'card'}>
         <h2 className={'card-title'}>Reservá tu lugar en la ofi</h2>
         <div className={'card-content'}>
            <label className={'input-label'}>
               Seleccioná una fecha
               <DatePicker
                  minDate={new Date()}
                  locale="es"
                  dateFormat={'dd/M/yyyy'}
                  selected={bookedDate}
                  className={'large-input'}
                  onChange={(date) => setBookedDate(date || new Date())}
               />
            </label>
            <label className={'input-label'}>
               Seleccioná tu menú
               <select
                  name="food-menu-select"
                  onChange={handleSelectedFoodMenu}
                  className={'large-input'}
               >
                  <option key={0} value="0">
                     Elegí una opción...
                  </option>
                  {FOOD_OPTIONS.map((food) => (
                     <option key={food.id} value={food.id}>
                        {food.icon + ' ' + food.description}
                     </option>
                  ))}
               </select>
            </label>
            <div className={'checkbox-container'}>
               <input type="checkbox" onChange={handleCheckKeys} />
               <label>Tengo llave de la ofi</label>
            </div>
            {userHasKeys && (
               <label className={'input-label'}>
                  ¿A qué hora llegás?
                  <input
                     type="text"
                     placeholder={'09:00'}
                     className={'large-input'}
                     maxLength={5}
                     onChange={handleKeyArrivalTimeChange}
                  />
               </label>
            )}
         </div>
         <div data-cy={"boton-reservar"} className="button-container">
            <Button onClick={handleBookingPress} isDisabled={isBookingDisabled}>
               {loadingCreateBooking ? (
                  <TailSpin
                     color="#fff"
                     visible={true}
                     height={16}
                     width={16}
                  />
               ) : (
                  'RESERVÁ PERRI'
               )}
            </Button>
         </div>
         {successfulBooking && (
            <p className={'booking-message'}>Reserva exitosa!</p>
         )}
         {hasErrorCreateBooking && (
            <p className={'booking-message'}>{createBookingErrorMessage}</p>
         )}
      </div>
   );
};

export default BookingCard;
