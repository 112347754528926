import { User } from 'firebase/auth';
import React, { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import CombosCard from '../../components/ranking/combos/CombosCard';
import FoodCard from '../../components/ranking/food/FoodCard';
import PeopleCard from '../../components/ranking/people/PeopleCard';
import useRanking from '../../hooks/useRanking';
import './RankingScreen.css';

type RankingScreenType = {
   user?: User | null | undefined;
};

const RankingScreen = ({ user }: RankingScreenType) => {
   const [searchParams] = useSearchParams();

   const {
      peopleTop10,
      getPeopleTop10,
      loadingPeopleTop10,
      peopleFullRanking,
      getPeopleFullRanking,
      loadingPeopleFullRanking,
      foodRanking,
      getFoodRanking,
      loadingFoodRanking,
      combosTop10,
      getCombosTop10,
      loadingCombosTop10,
      combosFullRanking,
      getCombosFullRanking,
      loadingCombosFullRanking,
   } = useRanking(user);

   useEffect(() => {
      getPeopleTop10();
      getPeopleFullRanking();
      getFoodRanking();
      getCombosTop10();
      getCombosFullRanking();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user]);

   const full = searchParams.has('full');
   const loading =
      loadingPeopleTop10 ||
      loadingPeopleFullRanking ||
      loadingFoodRanking ||
      loadingCombosTop10 ||
      loadingCombosFullRanking;
   return (
      <>
         {!loading ? (
            <div
               key={'card-container-12s3123'}
               className={'RankingCardContainer '}
            >
               <PeopleCard
                  ranking={full ? peopleFullRanking : peopleTop10}
                  full={full}
               />
               <FoodCard ranking={foodRanking} />
               <CombosCard ranking={full ? combosFullRanking : combosTop10} />
            </div>
         ) : (
            <TailSpin color="#fff" visible={true} height={30} width={30} />
         )}
      </>
   );
};

export default RankingScreen;
