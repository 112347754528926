import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type ProtectedRouteType = {
   isUserLoggedIn: any;
   redirectPath: any;
   children: any;
};
const ProtectedRoute = ({
   isUserLoggedIn,
   redirectPath = '/login',
   children,
}: ProtectedRouteType) => {
   if (!isUserLoggedIn) {
      return <Navigate to={redirectPath} replace />;
   }

   return children ? children : <Outlet />;
};

export default ProtectedRoute;
