import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FOOD_OPTIONS } from '../../../config/constants';
import { FoodSelection } from '../../../config/types';
import './food-card.css';

type FoodCardProps = {
   ranking: FoodSelection[];
};

const FoodCard = ({ ranking }: FoodCardProps) => {
   return (
      <div className={'food-card'}>
         <h2 className={'card-title'}>Comidas</h2>
         <div className={'card-content'}>
            {ranking.map((food, index) => (
               <div key={index} className={'card-content-item'}>
                  <span className="card-content-item-medal">
                     {index === 0 && '🥇'}
                     {index === 1 && '🥈'}
                     {index === 2 && '🥉'}
                  </span>
                  <span>
                     {
                        FOOD_OPTIONS.find(
                           (option) => option.icon === food.foodMenu,
                        )?.description
                     }
                     {food.foodMenu}{' '}
                  </span>
                  <span>{food.count}</span>
               </div>
            ))}
         </div>
      </div>
   );
};

export default FoodCard;
