import {
   GoogleAuthProvider,
   signInWithPopup,
   signOut,
   User,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { allowedEmailDomain } from '../config/constants';
import { authFirebase } from '../config/firebase-config';

const LOCAL_TEST_USER = {
   displayName: 'Usuario Test Local',
   photoURL: null,
   getIdToken: async (param: boolean) => {
      return 'sometoken';
   },
   email: 'usuario.prueba@' + allowedEmailDomain,
} as User;

type UseAuthUserProps = {
   isLocal: boolean;
};

export const useAuthUser = ({ isLocal }: UseAuthUserProps) => {
   const [user, loading, error] = useAuthState(authFirebase);
   const [userInformation, setUserInformation] = useState<User | null>();

   const login = () => {
      if (isLocal) return Promise.resolve({ user: LOCAL_TEST_USER });

      const provider = new GoogleAuthProvider();
      return signInWithPopup(authFirebase, provider);
   };

   const logout = () => {
      if (isLocal) return Promise.resolve(null);

      return signOut(authFirebase);
   };

   useEffect(() => {
      if (isLocal) {
         setUserInformation(LOCAL_TEST_USER);
      } else {
         setUserInformation(user);
      }
   }, [user, isLocal]);

   return { login, user: userInformation, loading, error, logout };
};
