import React from 'react';
import './booking-info.css';
import { bookinfInfoHidden } from '../../../config/toggleFeature';
import Button from '../../button/Button';

type BookingInfoProps = {
   onClose: () => void;
};

const BookingInfo = ({ onClose }: BookingInfoProps) => {
   return (
      <div className={'info bold-text'} hidden={bookinfInfoHidden}>
         <h2>Aviso</h2>
         <ul className={'list'}>
            <li>
               Anotate al menos un día antes para ir a la oficina de Rivadavia,
               así contemplamos el espacio y la comida
            </li>
            <li>
               Los Lunes, Martes y Miércoles son los días que Alejo y Celva
               estarán en la oficina.
            </li>
            <li>Celva y Alejo llegan a las 10hs</li>
            <li>Cualquier cosa llamá a Alejo en el horario hábil</li>
         </ul>
         <div className="info-button-container">
            <div className="info-button-context ">
               <Button onClick={onClose}>👌 ENTENDIDO PERRI!</Button>
            </div>
         </div>
      </div>
   );
};

export default BookingInfo;
