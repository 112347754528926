import Button from '../button/Button';
import './login-card.css';
import React from 'react';
import IllustrationTeam from '../../img/illustration-team.svg';

type LoginCardType = {
   login: () => void;
};

const LoginCard = ({ login }: LoginCardType) => {
   return (
      <div className={'card'}>
         <img className={'logo'} src={IllustrationTeam} alt="team working" />
         <h3 className={'card-subtitle'} data-testid="titulo-login">
            Ingresá a la App de reservas
         </h3>
         <Button onClick={login}>LOGIN</Button>
         <p className="feedback-text">
            💡 Si se te ocurren ideas para mejorar este proyecto, no dudes en
            contactarnos!
         </p>
      </div>
   );
};

export default LoginCard;
