import { User } from 'firebase/auth';
import { useCallback, useState } from 'react';
import { CombosData, FoodSelection, PeopleAttendance } from '../config/types';
import useApiClient from './useApiClient';

const useRanking = (user: User | null | undefined) => {
   const apiClient = useApiClient(user);

   const [loadingPeopleTop10, setLoadingPeopleTop10] = useState(false);
   const [peopleTop10, setPeopleTop10] = useState<PeopleAttendance[]>([]);
   const [loadingPeopleFullRanking, setLoadingPeopleFullRanking] =
      useState(false);
   const [peopleFullRanking, setPeopleFullRanking] = useState<
      PeopleAttendance[]
   >([]);
   const [loadingFoodRanking, setLoadingFoodRanking] = useState(false);
   const [foodRanking, setFoodRanking] = useState<FoodSelection[]>([]);
   const [loadingCombosTop10, setLoadingCombosTop10] = useState(false);
   const [combosTop10, setCombosTop10] = useState<CombosData[]>([]);
   const [loadingCombosFullRanking, setLoadingCombosFullRanking] =
      useState(false);
   const [combosFullRanking, setCombosFullRanking] = useState<CombosData[]>([]);

   const getPeopleTop10 = useCallback(async () => {
      setLoadingPeopleTop10(true);
      const response = await apiClient.get(
         '/ranking/current-year/people?limit=10',
      );

      setPeopleTop10(response.data);
      setLoadingPeopleTop10(false);
   }, [apiClient]);

   const getPeopleFullRanking = useCallback(async () => {
      setLoadingPeopleFullRanking(true);
      const response = await apiClient.get('/ranking/current-year/people');

      setPeopleFullRanking(response.data);
      setLoadingPeopleFullRanking(false);
   }, [apiClient]);

   const getFoodRanking = useCallback(async () => {
      setLoadingFoodRanking(true);
      const response = await apiClient.get(
         '/ranking/current-year/food?limit=10',
      );

      setFoodRanking(response.data);
      setLoadingFoodRanking(false);
   }, [apiClient]);

   const getCombosTop10 = useCallback(async () => {
      setLoadingCombosTop10(true);
      const response = await apiClient.get(
         '/ranking/current-year/combos?limit=10',
      );

      setCombosTop10(response.data);
      setLoadingCombosTop10(false);
   }, [apiClient]);

   const getCombosFullRanking = useCallback(async () => {
      setLoadingCombosFullRanking(true);
      const response = await apiClient.get('/ranking/current-year/combos');

      setCombosFullRanking(response.data);
      setLoadingCombosFullRanking(false);
   }, [apiClient]);

   return {
      getPeopleTop10,
      peopleTop10,
      loadingPeopleTop10,
      getPeopleFullRanking,
      peopleFullRanking,
      loadingPeopleFullRanking,
      getFoodRanking,
      foodRanking,
      loadingFoodRanking,
      getCombosTop10,
      combosTop10,
      loadingCombosTop10,
      getCombosFullRanking,
      combosFullRanking,
      loadingCombosFullRanking,
   };
};

export default useRanking;
