import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import { useAuthUser } from './hooks/useAuthUser';
import HomeScreen from './pages/home/HomeScreen';
import LoginScreen from './pages/login/LoginScreen';
import ProtectedRoute from './pages/ProtectedRoute';
import RankingScreen from './pages/ranking/RankingScreen';

type AppParams = {
   isLocal?: boolean;
};

function App({ isLocal = false }: AppParams) {
   const { user, loading, logout } = useAuthUser({ isLocal });

   return (
      <div className={'app'}>
         <Router>
            <Header
               user={user}
               isLoggedUser={user != null}
               logout={() => void logout()}
            />
            {loading ? (
               <TailSpin color="#fff" visible={true} height={30} width={30} />
            ) : (
               <Routes>
                  <Route
                     path="/"
                     element={
                        <ProtectedRoute
                           isUserLoggedIn={user != null}
                           redirectPath={'/login'}
                        >
                           <HomeScreen user={user} />
                        </ProtectedRoute>
                     }
                  ></Route>
                  <Route
                     path="/ranking"
                     element={<RankingScreen user={user} />}
                  ></Route>
                  <Route path="login" element={<LoginScreen />}></Route>
               </Routes>
            )}
         </Router>
      </div>
   );
}

export default App;
