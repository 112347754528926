export const FOOD_OPTIONS = [
   {
      id: 1,
      description: 'morfo todo',
      icon: '🍖',
   },
   {
      id: 2,
      description: 'vegeta',
      icon: '🥕',
   },
   {
      id: 3,
      description: 'vegan',
      icon: '🥬',
   },
   {
      id: 4,
      description: 'sin TACC',
      icon: '🌮',
   },
   {
      id: 5,
      description: 'llevo mi propio almuerzo',
      icon: '🧑‍🍳',
   },
   {
      id: 6,
      description: 'vegeta + sin TACC',
      icon: '🥕🌮',
   },
];

export const LOCAL_ENV: string = 'local';

export const allowedEmailDomain = 'grupoesfera.com.ar';
