import React, { useCallback, useEffect, useState } from 'react';
import BookingCard from '../../components/booking/card/BookingCard';
import BookingTable from '../../components/booking/table/BookingTable';
import { User } from 'firebase/auth';
import useBooking from '../../hooks/useBooking';
import { TailSpin } from 'react-loader-spinner';
import BookingInfo from '../../components/booking/info/BookingInfo';
import './HomeScreen.css';
import OfficeCard from '../../components/officeInfo/card/OfficeCard';

type HomeScreenType = {
   user?: User | null | undefined;
};

const HomeScreen = ({ user }: HomeScreenType) => {
   const [showInfo, setShowInfo] = useState<boolean>(true);

   const closeInfo = useCallback(() => {
      setShowInfo(false);
   }, []);

   const {
      getBookings,
      deleteBooking,
      createBooking,
      hasErrorCreateBooking,
      bookings,
      loadingDeleteBooking,
      loadingGetBookings,
      loadingCreateBooking,
      successfulBooking,
      createBookingErrorMessage,
   } = useBooking(user);

   const handleDeletePress = useCallback(
      async (id) => {
         await deleteBooking(id);
         await getBookings();
      },
      [deleteBooking, getBookings],
   );

   useEffect(() => {
      getBookings();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         {!loadingGetBookings ? (
            <>
               {showInfo && <BookingInfo onClose={closeInfo} />}
               <div key={'card-container-123123'} className={'CardContainer '}>
                  <BookingCard
                     getBookings={getBookings}
                     createBooking={createBooking}
                     loadingCreateBooking={loadingCreateBooking}
                     successfulBooking={successfulBooking}
                     hasErrorCreateBooking={hasErrorCreateBooking}
                     createBookingErrorMessage={createBookingErrorMessage}
                     user={user}
                     selectedDate={new Date()}
                  />
                  <OfficeCard />
               </div>
               <BookingTable
                  bookings={bookings}
                  loadingDeleteBooking={loadingDeleteBooking}
                  userDisplayName={user?.displayName}
                  deleteBooking={handleDeletePress}
               />
            </>
         ) : (
            <TailSpin color="#fff" visible={true} height={30} width={30} />
         )}
      </>
   );
};

export default HomeScreen;
